/**
 * @format
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addProduct, updateCartTotal } from '../actions/cartAction';
import { ProductType } from '../flowObjectType'
const _ = require('lodash')

type State = {
  products: ProductType[],
}

type Props = {
  products: ProductType[],
  recommendation: any[],
  showProduct: Function,
  close: Function,
}

class ProductRecommendation extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    const recommendedProducts = [];
    if (props.recommendation?.recommendedProducts?.length > 0 && props.products?.length > 0) {
      for (let i = 0; i < props.products.length; ++i) {
        const product = props.products[i]
        for (let j = 0; j < props.recommendation.recommendedProducts.length; ++j) {
          if (product.id === props.recommendation.recommendedProducts[j]) {
            recommendedProducts.push({
              ...product
            });
          }
        }

        if (recommendedProducts.length === props.recommendation.recommendedProducts.length) {
          break;
        }
      }
    }

    this.state = {
      products: recommendedProducts
    }
  }

  getCartPrice = () => {
    const cart = this.props.cart
    if (cart.products.length === 0) {
      this.props.updateCartTotal(0, 0, 0, 0, 0)
      return
    }
    let cartTotal = 0
    let cartTotalHT = 0
    let cartTotalVat = 0
    let cartTotalProducts = 0
    let cartSubtotal = 0
    cart.products.forEach((product) => {
      const { total, totalHT, totalVat, subtotal } = this.getProductPrice(product, product.quantity, true)
      cartTotal += total
      cartTotalHT += totalHT
      cartTotalVat += totalVat
      cartTotalProducts += product.quantity
      cartSubtotal += subtotal
    })
    this.props.updateCartTotal(cartTotal, cartTotalHT, cartTotalVat, cartTotalProducts, cartSubtotal)
  }

  getProductPrice = (product: ProductType, quantity: number, forTotal: boolean): { total: number, totalHT: number, totalVat: number, subtotal: number } => {
    const rubric = product.rubric
    const orderType = this.props.cart.orderType;

    if (typeof product === 'undefined') return { total: 0, totalHT: 0, totalVat: 0 }
    let total = quantity * product.unitPrice
    let totalHT = quantity * product.unitPriceHT

    rubric.forEach((section) => {
      section.data.forEach((rubric) => {
        if (rubric.checked && rubric.unitPrice > 0) {
          if (section.allowSameMultiple) {
            total += quantity * rubric.unitPrice * (rubric.quantity || 0)
            totalHT += quantity * rubric.unitPriceHT * (rubric.quantity || 0)
          }
          else {
            total += quantity * rubric.unitPrice
            totalHT += quantity * rubric.unitPriceHT
          }
        }
      })
    })

    // apply product discount
    if (orderType === 1 && product.takeawayDiscount && product.takeawayDiscount.discount) {
      total = total * (1 - product.takeawayDiscount.discount / 100)
      totalHT = totalHT * (1 - product.takeawayDiscount.discount / 100)
    }
    else if (orderType === 4 && product.deliveryDiscount && product.deliveryDiscount.discount) {
      total = total * (1 - product.deliveryDiscount.discount / 100)
      totalHT = totalHT * (1 - product.deliveryDiscount.discount / 100)
    }
    else if ((orderType === 2 || orderType === 3) && product.tableDiscount && product.tableDiscount.discount) {
      total = total * (1 - product.tableDiscount.discount / 100)
      totalHT = totalHT * (1 - product.tableDiscount.discount / 100)
    }

    let subtotal = total

    // apply cart discount
    if (this.props.cart.discount) {
      if (
        (orderType === 1 && (!product.takeawayDiscount || (product.takeawayDiscount && product.takeawayDiscount.canDiscount))) ||
        (orderType === 4 && (!product.deliveryDiscount || (product.deliveryDiscount && product.deliveryDiscount.canDiscount))) ||
        ((orderType === 2 || orderType === 3) && (!product.tableDiscount || (product.tableDiscount && product.tableDiscount.canDiscount)))
      ) {
        total = total * (1 - this.props.cart.discount / 100)
        totalHT = totalHT * (1 - this.props.cart.discount / 100)
      }
    }

    // if (typeof product.discount !== 'undefined' && product.discount !== null && !product.discount.isFidelityDiscount) {
    //   total = total * (1 - product.discount.percentage / 100)
    //   totalHT = totalHT * (1 - product.discount.percentage / 100)
    // }

    let totalVat = total - totalHT
    return { total, totalHT, totalVat, subtotal }
  }

  addProduct = async (p) => {
    if (p.rubrics?.length > 0) {
      this.props.showProduct(p)();
      this.props.close(false);
      return;
    }

    const product = _.cloneDeep(p)

    const { total, totalHT, totalVat, subtotal } = this.getProductPrice(product, 1, false)

    const newProduct = {
      id: product.id,
      quantity: 1,
      name: product.name,
      description: product.description ? product.description : '',
      unitPrice: product.unitPrice,
      unitPriceHT: product.unitPriceHT,
      discount: product.discount || null,
      tableDiscount: product.tableDiscount || null,
      takeawayDiscount: product.takeawayDiscount || null,
      deliveryDiscount: product.deliveryDiscount || null,
      image: product.image || null,
      vat: product.vat,
      rubric: [],
      total: total,
      totalHT: totalHT,
      totalVat: totalVat,
      subtotal: subtotal,
    }

    await this.props.addProduct(newProduct)
    this.getCartPrice();
  }

  render() {
    return (
      <div className="">
        <div className="flex justify-center items-center h-28">
          <p className="text-2xl"> Nous vous recommandons aussi</p>
        </div>

        <div className="toast toast-top toast-center">
          <div className="alert alert-info">
            <span>New mail arrived.</span>
          </div>
          <div className="alert alert-success">
            <span>Message sent successfully.</span>
          </div>
        </div>
        {/* <h4> Souvent commandé ensemble</h4> */}
        <div className="grid gap-2 grid-cols-3 p-2">
          {this.state.products.map(p => {
            const hasImage = typeof p.image !== 'undefined' && p.image.path !== '' && p.image.enable
            const price = p.canOrder ? `${p.unitPrice.toFixed(2)} €` : `${p.unitPrice.toFixed(2)} € (indisponible)`
            return (
              <div className="product-block border-2 border-slate-300 rounded flex flex-col justify-center items-center">
                {hasImage && (
                  <img
                    alt="produit"
                    className="product-img"
                    src={p.image.path}
                  />
                )}
                <div className="product-text-container text-center">
                  <div className="product-box-name">
                    <div className="product-name">{p.name}</div>
                  </div>
                  {p.unitPrice > 0 && (
                    <div className={`product-price ${!p.canOrder ? 'product-not-available' : ''}`}>
                      {price}
                    </div>
                  )}
                </div>
                <button onClick={() => this.addProduct(p)} className="!btn !btn-active glass">Ajouter</button>
              </div>
            )
          })}
        </div>


        <div className="flex justify-center items-center h-28">
          <button onClick={() => this.props.close(true)} className="!btn !btn-outline btn-lg">Continuer au panier</button>
        </div>
      </div>
    )
  }
}

// redux
const mapStateToProps = (state) => {
  return {
    cart: state.cart
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addProduct: (product) => dispatch(addProduct(product)),
    updateCartTotal: (cartTotal, cartTotalHT, cartTotalVat, cartTotalProducts, cartSubtotal) => dispatch(updateCartTotal(cartTotal, cartTotalHT, cartTotalVat, cartTotalProducts, cartSubtotal)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductRecommendation)

// @flow

import React, { Component } from 'react';
import { Breakpoint } from 'react-socks';
import { Link, Redirect } from "react-router-dom";
import { Container, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { FaCheckCircle } from "react-icons/fa";
import { RiEBike2Line, RiShoppingBagLine } from "react-icons/ri";
import Masonry from 'react-masonry-css'
import differenceInHours from 'date-fns/differenceInHours';
import * as animationData from '../assets/loading.json'
import * as animationData404 from '../assets/404.json'
import { withTranslation } from 'react-i18next'

import '../styles/Restaurant.scss'
import { updateRestoInfo, updateType, resetCart, applyDiscount, applyCoupon, pickedOrderType, setDiscountSettings } from '../actions/cartAction';
import { updateRestoSettings, updateDeliverySchedule, updateOrderSchedule, updateDeliverySettings, updateTakeawaySettings } from '../actions/settingsAction';
import { flushRecommendations, incrementRecommendationCount, setHasRefused, setRecommendation1, setRecommendation2 } from '../actions/recommendationAction';
import { updateColors } from '../actions/colorsAction'

import ProductDetail from './ProductDetail'
import $ from "jquery"
import dayjs from 'dayjs'

import { getBusinessTypes, getOrderType, parseSchedule, computeDiscount } from './Utils'
import ProductCartRow from './ProductCartRow'
import ProductRecommendation from './ProductRecommendation'
import { CartType, ProductType } from '../flowObjectType'
import { firestore, analytics } from '../services/firebase'
import FooterBar from './FooterBar'

import spicy1 from "../assets/classification/spicy1.webp"
import spicy2 from "../assets/classification/spicy2.webp"
import spicy3 from "../assets/classification/spicy3.webp"
import homemade from "../assets/classification/homemade.webp"
import vegetarian from "../assets/classification/vegetarian.png"
import recommended from "../assets/classification/recommended.png"
import halal from "../assets/classification/halal.webp"

const ImageData = {}
ImageData['vegetarian'] = vegetarian
ImageData['recommended'] = recommended
ImageData['halal'] = halal
ImageData['homemade'] = homemade
ImageData['spicy1'] = spicy1
ImageData['spicy2'] = spicy2
ImageData['spicy3'] = spicy3


// color
// text black
// background #F1F1F1
// blue #00CCBB
// yellow #FDC151
// red #FF4B4B

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 2
};

type Props = {
  match: Object,
  cart: CartType,
  settings: Object,
  updateType: Function,
  updateRestoInfo: Function,
  updateRestoSettings: Function,
  updateDeliverySchedule: Function,
  updateOrderSchedule: Function,
  resetCart: Function,
  updateTakeawaySettings: Function,
  updateDeliverySettings: Function,
  flushRecommendation: Function,
  recommendation: Object,
  updateColors: Function
}

type State = {
  canOrder: boolean,
  settings: Object,
  informations: Object,
  showProductDetail: boolean,
  showRecommendation: boolean,
  selectedRecommendation: Object,
  restaurant: Object,
  sections: Array<Object>,
  selectedProduct: ?ProductType,
  invalidQrCode: boolean,
  selectedCategoryIdx: number,
  selectedIdx: ?number,
  orderType: ?number,
  table: ?string,
  redirectToCart: boolean,
}

function goToAnchor(event: Object, id: string) {
  const productPositionY = $(`#section-${id}`).offset().top - 65
  // $(event.target).css('backgroundColor', 'white')
  $("html, body").animate({ scrollTop: productPositionY }, 500);
  event.preventDefault();
}

let savedSticky = 0
function handleStickyHeader(header) {
  let sticky = header[0].offsetTop
  if (header.hasClass("sticky")) {
    sticky = savedSticky
  }

  if (window.pageYOffset > sticky) {
    savedSticky = sticky
    header.addClass("sticky");
  } else {
    header.removeClass("sticky");
  }
}
function handleActiveSection(header) {

  const topMenuHeight = header.outerHeight() + 10
  const menuItems = header.find(".options-btn")
  const scrollItems = menuItems.map(function () {
    var item = $($(this).attr("href"));
    if (item.length) { return item; }
    return null
  });

  var fromTop = window.scrollY + topMenuHeight;
  var lastId;
  // Get id of current scroll item

  var cur = scrollItems.map(function () {
    if ($(this).offset().top < fromTop)
      return this;
    return null
  });
  // Get the id of the current element
  cur = cur[cur.length - 1];
  var id = cur && cur.length ? cur[0].id : "";

  if (lastId !== id) {
    lastId = id;
    // Set/remove active class
    if (id === "") return
    menuItems.removeClass("selected")
    const selectedItem = menuItems.filter("[href='#" + id + "']")
    selectedItem.addClass("selected");
    if (selectedItem[0]) {
      selectedItem[0].scrollIntoView({
        behavior: 'instant',
      });
    }
  }

}

const CategoryRow = (props) => props.category.displayApp === false ?
  (<></>)
  : (
    <>
      <a href={`#section-${props.category.id}`} className={`options-btn ${props.selected ? 'selected' : ''}`} onClick={(event) => { goToAnchor(event, props.category.id) }}>
        {props.category.name}
      </a>
    </>
  )

const CategoryLogo = (props) => (
  <>
    <a href={`#section-${props.category.id}`} className={`options-btn ${props.selected ? 'selected' : ''}`} onClick={(event) => { goToAnchor(event, props.category.id) }}>
      <div className='menu-product-image'>
        {props.category.image?.path && (
          <img className="category-img" src={props.category.image?.path} />
        )}
      </div>
      {props.category.name}
    </a>
  </>
)

const Section = (props) => (
  <div>
    <h3 className="section" id={`section-${props.id}`}>
      <div className="section-title">{props.title.toUpperCase()}</div>
    </h3>
    {props.description && (
      <div className="section-description">
        {props.description}
      </div>
    )}
  </div>
)

const ClassificationImages = ({ classification }) => {
  return (
    <div>
      {classification && (classification.spicyLevel > 0 ?? false) && (
        <img
          key={'spicy'}
          alt="produit"
          className="allergen-img"
          src={ImageData[`spicy${classification.spicyLevel}`]}
        />
      )}
      {classification && (classification.isVegetarian ?? false) && (
        <img
          key={'vegetarian'}
          alt="produit"
          className="allergen-img"
          src={ImageData['vegetarian']}
        />
      )}
      {classification && (classification.isRecommended ?? false) && (
        <img
          key={'recommended'}
          alt="produit"
          className="allergen-img"
          src={ImageData[`recommended`]}
        />
      )}
      {classification && (classification.isHomemade ?? false) && (
        <img
          key={'homemade'}
          alt="produit"
          className="allergen-img"
          src={ImageData[`homemade`]}
        />
      )}
      {classification && (classification.isHalal ?? false) && (
        <img
          key={'halal'}
          alt="produit"
          className="allergen-img"
          src={ImageData[`halal`]}
        />
      )}
    </div>
  );
};



const ProductRow = (props) => {
  const { product, orderType } = props
  const cartProducts = props.cartProducts ? props.cartProducts.filter(e => e.id === product.id) : [];
  const totalQuantity = cartProducts.length > 0 ? cartProducts.reduce((total, p) => total + p.quantity, 0) : 0;
  const price = `${product.unitPrice.toFixed(2)} €`
  const unitPrice = parseFloat(product.unitPrice);

  let discountPrice = ``;
  let hasDiscount = false;
  let discountLabel = '';

  if (orderType === 1 && product.takeawayDiscount && product.takeawayDiscount.discount) {
    discountPrice = unitPrice * (1 - product.takeawayDiscount.discount / 100)
    hasDiscount = true;
    discountLabel = `${product.takeawayDiscount.discount}% `
  }
  else if (orderType === 4 && product.deliveryDiscount && product.deliveryDiscount.discount) {
    discountPrice = unitPrice * (1 - product.deliveryDiscount.discount / 100)
    hasDiscount = true;
    discountLabel = `${product.deliveryDiscount.discount}% `
  }
  else if ((orderType === 2 || orderType === 3) && product.tableDiscount && product.tableDiscount.discount) {
    discountPrice = unitPrice * (1 - product.tableDiscount.discount / 100)
    hasDiscount = true;
    discountLabel = `${product.tableDiscount.discount}% `
  }

  const classification = product.classification ?? {}

  return (
    <div className={`product-box ${cartProducts.length > 0 ? 'selected' : ''}`} onClick={props.toggleModal(product)}>
      <div className="product-text-container">

        <div className="product-allergen">
          <ClassificationImages classification={classification} />
        </div>

        <div className="product-box-name">
          <div className="product-name">{product.name}</div>
        </div>
        {product.description !== "" && (
          <div className="product-description">
            <p>
              {product.description}
            </p>
          </div>
        )}

        {hasDiscount && (
          <div className={`product-price ${!product.canOrder ? 'product-not-available' : ''}`} style={{ alignItems: 'center' }}>
            <span style={{ textDecoration: 'line-through' }}>{unitPrice.toFixed(2)} €</span>
            <span>{`    -${discountLabel} soit ${discountPrice.toFixed(2)} €`}</span>
            {!product.canOrder && (`indisponible`)}
          </div>
        )}
        {!hasDiscount && product.unitPrice > 0 && (
          <div className={`product-price ${!product.canOrder ? 'product-not-available' : ''}`}>
            {price}
            {!product.canOrder && (`indisponible`)}
          </div>
        )}
      </div>
      {product.image.path !== '' && (
        <div className="image-container">
          <img
            className="img"
            src={product.image.path}
            alt=""
          />
        </div>
      )}
      {cartProducts.length > 0 && (
        <div className="product-box-quantity">
          {totalQuantity}
        </div>
      )}
    </div>
  )
}
const MySwal = withReactContent(Swal)

const getResto = async (restoName) => {
  const docs = await firestore()
    .collection('Restaurant')
    .where('urlName', '==', restoName)
    .get();

  if (!docs.empty) {
    const data = {
      id: docs.docs[0].id,
      ...docs.docs[0].data(),
    }

    return data;
  }
  else {
    return null;
  }
}

class Restaurant extends Component<Props, State> {
  unsubscribe: Object
  refRestaurant: Object
  refProduct: Object
  refCategory: Object
  refDiscount: Object
  refOption: Object
  readOnly: boolean
  products: Array<Object>
  categories: Array<Object>
  discounts: Array<Object>
  options: Array<Object>
  selectCategory: Function

  constructor(props: Object) {
    super(props);

    this.unsubscribe = null
    this.refRestaurant = null
    this.refProduct = null
    this.refCategory = null
    this.refDiscount = null
    this.refOption = null

    this.readOnly = false
    this.allProducts = []
    this.products = []
    this.categories = []
    this.discounts = []
    this.options = []
    this.recommendations = []

    this.state = {
      canOrder: false,
      settings: null,
      informations: null,
      showProductDetail: false,
      showRecommendation: false,
      restaurant: null,
      sections: [],
      selectedProduct: null,
      selectedRecommendation: null,
      invalidQrCode: false,
      selectedCategoryIdx: 0,
      selectedIdx: undefined,
      orderType: undefined,
      table: null,
      redirectToCart: false,
    }
  }

  componentDidMount() {
    const cartCreatedAt = new Date(this.props.cart.createdAt);
    if (this.props.cart.flushCart === true || isNaN(cartCreatedAt) || differenceInHours(new Date(), cartCreatedAt) >= 10) {
      this.props.resetCart();
      this.props.flushRecommendation();
    }
    const id = this.props.match.params.id

    if (id) {
      this.handleClickAndCollect()
    }
  }

  handleClickAndCollect = async () => {
    const restoName = this.props.match.params.id.toLowerCase()
    let orderType = this.props.cart.orderType === 1 || this.props.cart.orderType === 4 ? this.props.cart.orderType : 1;

    const type = this.props.match.params.orderType?.toLowerCase()
    if (type && type === 'emporter')
      orderType = 1
    if (type && type === 'livraison')
      orderType = 4
    if (type && type === 'kiosk')
      orderType = 2
    // shinzo menu pdf
    // if (restoName === 'shinzzo') {
    //   window.location.replace('https://we-eats.com/menu/shinzzo.pdf')
    // }
    const resto = await getResto(restoName)
    if (resto === null) {
      this.setState({ invalidQrCode: true })
      Swal.fire("QR Code non valide", "Veuillez réessayer un autre QR Code", "error");
      return
    }
    this.initRestaurant(resto.id)
    this.setState({ orderType: orderType })
    this.props.setDiscountSettings({ takeawayDiscount: { discount: this.props.settings.takeawaySettings.discount, discountFrom: this.props.settings.takeawaySettings.discountFrom }, deliveryDiscount: { discount: this.props.settings.deliverySettings.discount, discountFrom: this.props.settings.deliverySettings.discountFrom } })
    this.props.applyDiscount(orderType === 1 ? this.props.settings.takeawaySettings.discount || 0 : orderType === 4 ? this.props.settings.deliverySettings.discount || 0 : 0);
    this.props.updateType(orderType);
  }


  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe()
  }

  loadScript = () => {
    const header = $("#sticky-header");
    if (header.length) {
      window.onscroll = () => {
        handleActiveSection(header)
        handleStickyHeader(header)
      }
    }
  }

  initRestaurant = (restoId) => {
    this.refRestaurant = firestore().collection('Restaurant').doc(restoId)
    this.refProduct = firestore().collection(`Restaurant/${restoId}/Product`)
    this.refOption = firestore().collection(`Restaurant/${restoId}/Rubric`)
    this.refCategory = firestore().collection(`Restaurant/${restoId}/Category`).where('status', '==', 1)
    this.refDiscount = firestore().collection(`Restaurant/${restoId}/Discount`)
    this.refRecommendation = firestore().collection(`Restaurant/${restoId}/Recommendation`)

    this.unsubscribe = this.refRestaurant.onSnapshot(this.onRestoUpdate)
    this.unsubscribeProduct = this.refProduct.onSnapshot(this.onProductUpdate)
    this.unsubscribeCategory = this.refCategory.onSnapshot(this.onCategoryUpdate)

    const productP = this.refProduct.get()
    const categoryP = this.refCategory.get()
    const discountP = this.refDiscount.get()
    const optionP = this.refOption.get()
    const recoP = this.refRecommendation.get()

    Promise.all([productP, categoryP, discountP, optionP, recoP])
      .then(([product, category, discount, option, reco]) => {
        const products = []
        const categories = []
        const discounts = []
        const options = []
        const recos = []

        product.forEach((doc) => {
          const { createdAt, ...data } = doc.data()
          const typeKey = this.state.orderType === 1 ? 'takeaway' : (this.state.orderType === 4 ? 'delivery' : null);
          this.allProducts.push({ id: doc.id, ...data });
          // Combine conditions and add to products if conditions are met
          if ((data.display?.[typeKey] === true) || (data.display?.[typeKey] === undefined && (data.displayApp || data.displayApp === undefined))) {
            products.push({ id: doc.id, ...data });
          }
        });
        category.forEach((doc) => {
          const { createdAt, ...data } = doc.data()
          if (!data.internal && (data.displayApp || data.displayApp === undefined)) categories.push({ id: doc.id, ...data })
        });
        discount.forEach((doc) => {
          const { createdAt, ...data } = doc.data()
          discounts.push({ id: doc.id, ...data })
        });
        option.forEach((doc) => {
          const { id, products, shared, ...data } = doc.data()
          options.push({ id: doc.id, ...data })
        })
        reco.forEach((doc) => {
          recos.push({
            id: doc.id,
            ...doc.data(),
          });
        })
        this.products = products
        this.categories = categories.sort((a, b) => (a.index < b.index ? -1 : 1))
        this.discounts = discounts
        this.options = options
        this.recommendations = recos
        this.getSections()
        return true
      })
      .catch(err => {
        console.log(err)
        return false
      })
  }

  onRestoUpdate = (querySnapshot: Object) => {
    const restaurant = querySnapshot.data()
    const orderSchedule = parseSchedule(restaurant.settings.orderSchedule);
    const deliverySchedule = parseSchedule(restaurant.settings.deliverySchedule);

    const deliverySettings = restaurant.deliverySettings || {};
    const takeawaySettings = restaurant.takeawaySettings || {};

    this.props.updateRestoInfo(restaurant.informations.id, restaurant.informations.name, restaurant.informations.postalCode, restaurant.state.canOrder, restaurant.informations);
    this.props.updateRestoSettings(restaurant.informations.id, restaurant.settings.color, restaurant.settings.autoAcceptOrder, restaurant.settings.delivery, restaurant.settings.takeaway, restaurant.settings.table, restaurant.settings.stripe || '', restaurant.settings.orderTypePopup || false);
    this.props.updateDeliverySchedule(deliverySchedule);
    this.props.updateOrderSchedule(orderSchedule);
    this.props.updateDeliverySettings(deliverySettings);
    this.props.updateTakeawaySettings(takeawaySettings);
    this.props.updateColors(restaurant.settings.useDarkTheme)

    // if (!restaurant.settings.delivery && restaurant.settings.takeaway === true) {
    //   console.log('handleOrderTypeChange 1')
    //   this.handleOrderTypeChange(1)
    // }
    // if (restaurant.settings.delivery === true && !restaurant.settings.takeaway) {
    //   console.log('handleOrderTypeChange 4')
    //   this.handleOrderTypeChange(4)
    // }
    if (!restaurant.settings.delivery && !restaurant.settings.takeaway && !restaurant.settings.table) {
      console.log('handleOrderTypeChange 0')
      this.handleOrderTypeChange(0)
      this.readOnly = true
    }


    analytics().logEvent('home_page', { restoId: restaurant.informations.id, restoName: restaurant.informations.name });


    setTimeout(() => {
      this.setState({
        settings: restaurant.settings,
        informations: restaurant.informations,
        restaurant: restaurant,
        canOrder: restaurant.state.canOrder
      })
      this.loadScript()

      if (this.state.orderType === 2 || this.state.orderType === 3) return

      if (restaurant.settings.orderTypePopup !== undefined && restaurant.settings.orderTypePopup && restaurant.settings.takeaway === true && restaurant.settings.delivery === true && this.props.cart.pickedOrderType === false) {
        MySwal.fire({
          title: <p>Vous souhaitez commander en</p>,
          confirmButtonText: <div><RiShoppingBagLine size="1.0em" /> Emporter</div>,
          cancelButtonText: <div><RiEBike2Line size="1.0em" /> Livraison</div>,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#3085d6',
          showCancelButton: true,
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.handleOrderTypeChange(1)
          } else {
            this.handleOrderTypeChange(4)
          }
          this.props.pickedOrderType()
        })
      }

    }, 1000)
  }

  onProductUpdate = (querySnapshot: Object) => {
    const products = []
    querySnapshot.forEach((doc) => {
      const { createdAt, ...data } = doc.data()
      const typeKey = this.state.orderType === 1 ? 'takeaway' : (this.state.orderType === 4 ? 'delivery' : null);

      // Combine conditions and add to products if conditions are met
      if ((data.display?.[typeKey] === true) || (data.display?.[typeKey] === undefined && (data.displayApp || data.displayApp === undefined))) {
        products.push({ id: doc.id, ...data });
      }
    });
    this.products = products
    this.getSections()
  }

  onCategoryUpdate = (querySnapshot: Object) => {
    const categories = []
    querySnapshot.forEach((doc) => {
      const { createdAt, ...data } = doc.data()
      if (!data.internal && (data.displayApp || data.displayApp === undefined)) categories.push({ id: doc.id, ...data })
    })
    this.categories = categories.sort((a, b) => (a.index < b.index ? -1 : 1))
    this.getSections()
  }

  handleQrCode = async (data: string) => {
    if ((data.length !== 20 && data.length !== 41) || (data.length === 41 && data[20] !== '-'))
      return null
    if (data.length === 20) {
      const restoId = data
      const ref = firestore().collection('Restaurant').doc(restoId)
      return await ref.get()
        .then(doc => {
          if (doc.exists) {
            return restoId
          } else {
            return null
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    else if (data.length === 41) {
      const restoId = data.split('-')[0]
      const tableId = data.split('-')[1]

      const ref = firestore().collection(`Restaurant/${restoId}/QRCode`).doc(tableId)
      return await ref.get()
        .then(doc => {
          if (doc.exists) {
            const orderType = 3
            this.props.setDiscountSettings({ takeawayDiscount: { discount: this.props.settings.takeawaySettings.discount, discountFrom: this.props.settings.takeawaySettings.discountFrom }, deliveryDiscount: { discount: this.props.settings.deliverySettings.discount, discountFrom: this.props.settings.deliverySettings.discountFrom } })
            this.props.applyDiscount(orderType === 1 ? this.props.settings.takeawaySettings.discount || 0 : orderType === 4 ? this.props.settings.deliverySettings.discount || 0 : 0);
            this.props.updateType(orderType);
            return restoId
          } else {
            return null
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  toggleModal = (product) => () => {
    if (product.canOrder)
      this.setState(prevState => ({
        selectedProduct: { ...product },
        showProductDetail: !prevState.showProductDetail,
        selectedIdx: undefined
      }))
  }

  closeProductModal = () => {
    this.setState({ showProductDetail: false })
  }

  openRecommendation = async () => {
    if (this.props.recommendation.hasRefused || this.props.recommendation.count >= 2) {
      return;
    }

    const { recommendation1, recommendation2 } = this.props.recommendation;

    if (recommendation1 === null || (recommendation1 === true && recommendation2 === null)) {
      const recoDocs = await this.refRecommendation.where('targetProducts', 'array-contains', this.state.selectedProduct.id).get();

      if (recoDocs.docs.length > 0) {
        const reco = recoDocs.docs[0].data();
        const { total, totalProducts } = this.props.cart;
        console.log(reco)
        if (total >= reco.minOrder && totalProducts >= reco.minProducts)
          this.setState({ showRecommendation: true, selectedRecommendation: reco });
      }
    }
  }

  closeProductRecommendation = (hasRefused) => {
    if (hasRefused) {
      this.props.setHasRefusedRecommendation();
    } else {
      this.props.incrementRecommendationCount();
    }

    this.setState({ showRecommendation: false, selectedRecommendation: null })
  }

  goToCart = async () => {
    const cartReco = await this.refRecommendation.doc('1').get()
    if (cartReco.exists) {
      const { total, totalProducts } = this.props.cart;
      const reco = cartReco.data();
      if (total >= reco.minOrder && totalProducts >= reco.minProducts) {
        this.setState({
          showRecommendation: true,
          selectedRecommendation: reco,
        })
      }
    }
    this.setState({
      redirectToCart: true,
    });
  }

  getSections = () => {
    const sections = []
    for (let i = 0; i < this.categories.length; i++) {
      const products = this.products.filter((item) => item.categoryId === this.categories[i].id).sort(((a, b) => (a.index < b.index ? -1 : 1)))
      if (products.length === 0) {
        this.categories[i].displayApp = false
        continue
      }
      sections.push({
        title: this.categories[i].name,
        description: this.categories[i].description,
        categoryMaxQuantity: this.categories[i].maxQuantity || 0,
        data: products,
        index: i,
        id: this.categories[i].id
      })
    }
    this.setState({ sections })
  }


  onClickCartProduct = (index) => e => {
    this.setState({
      selectedProduct: this.props.cart.products[index],
      showProductDetail: true,
      selectedIdx: index

    });
  };

  handleOrderTypeChange = (orderType) => {
    this.setState({ orderType });
    this.props.setDiscountSettings({ takeawayDiscount: { discount: this.props.settings.takeawaySettings.discount, discountFrom: this.props.settings.takeawaySettings.discountFrom }, deliveryDiscount: { discount: this.props.settings.deliverySettings.discount, discountFrom: this.props.settings.deliverySettings.discountFrom } })
    this.props.applyDiscount(orderType === 1 ? this.props.settings.takeawaySettings.discount || 0 : orderType === 4 ? this.props.settings.deliverySettings.discount || 0 : 0);
    this.props.updateType(orderType);
    const newProducts = []
    this.products.forEach((p) => {
      const typeKey = orderType === 1 ? 'takeaway' : (orderType === 4 ? 'delivery' : null);
      // Combine conditions and add to products if conditions are met
      if ((p.display?.[typeKey] === true) || (p.display?.[typeKey] === undefined && (p.displayApp || p.displayApp === undefined))) {
        newProducts.push(p);
      }
    });

    this.products = newProducts
    this.getSections()

  }

  getOrderTotal = () => {
    let total = this.props.cart.total;
    if (this.props.cart.coupon !== null) {
      if (this.props.cart.coupon?.discountType === 'percent') {
        total = Number.parseFloat(computeDiscount(total, this.props.cart.coupon.discountPercent, true).toFixed(2));
      }
      else if (this.props.cart.coupon?.discountType === 'price') {
        total -= this.props.cart.coupon.discountPrice;
      }
    }
    return total;
  }

  removeCoupon = () => {
    this.props.applyCoupon(null);
  }

  render() {
    if (this.state.invalidQrCode) {
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData404.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }
      return (
        <div id="not-found">
          <Breakpoint medium up>
            <Lottie
              options={defaultOptions}
            />
          </Breakpoint>
          <Breakpoint small down>
            <Lottie
              options={defaultOptions}
            />
          </Breakpoint>
        </div>
      )
    }
    if (!this.state.informations) {
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }

      return (
        <div className="center-loading">
          <Breakpoint medium up>
            <Lottie
              options={defaultOptions}
            />
          </Breakpoint>
          <Breakpoint small down>
            <Lottie
              options={defaultOptions}
            />
          </Breakpoint>
        </div>
      )
    }
    const now = new Date();
    const dayOfTheWeek = now.getDay() ? now.getDay() - 1 : 6;
    let checkoutLink = `/${this.props.match.params.id}/cart`

    if (this.state.redirectToCart && !this.state.showProductDetail && !this.state.showRecommendation) {
      return (
        <Redirect
          to={checkoutLink}
        />
      )
    }

    return (
      <Container className="container" id="main" >
        {
          this.props.settings.color && (
            <style> {`body {
              --website-color: ${this.props.settings.color}
            }`}
            </style>
          )
        }
        {
          this.props.colors && (
            <style> {`body {
              --colors-mainBg: ${this.props.colors.mainBg};
              --colors-main: ${this.props.colors.main};
              --colors-mainText: ${this.props.colors.mainText};
              --colors-categoryBg: ${this.props.colors.categoryBg};
              --colors-categoryBtnSelected: ${this.props.colors.categoryBtnSelected};
              --colors-categoryText: ${this.props.colors.categoryText};
              --colors-categoryTextSelected: ${this.props.colors.categoryTextSelected};
              --colors-productBg: ${this.props.colors.productBg};
              --colors-imgBorder: ${this.props.colors.imgBorder};
              --colors-categoryName: ${this.props.colors.categoryName};
              --colors-productBtn: ${this.props.colors.productBtn};
              --colors-productName: ${this.props.colors.productName};
              --colors-productDescription: ${this.props.colors.productDescription};
              --colors-productBorder: ${this.props.colors.productBorder};
              --colors-productPrice: ${this.props.colors.productPrice};
            }`}
            </style>
          )
        }
        <div className="header">

          <div className="header-image">
            <img
              className="restaurant-img"
              src={this.state.restaurant.photos.profilPath}
              alt="restaurant-logo"
            />
          </div>

          <div className="restaurant-info">
            <div className="restaurant-name">
              {this.state.informations.name}
            </div>

            {(this.state.orderType === 1 || this.state.orderType === 4) && (
              <>
                <div className="restaurant-info-text">
                  {getBusinessTypes(this.state.informations.businessType)}
                </div>

                <div className="restaurant-info-text">
                  <em>{this.state.informations.address} {this.state.informations.postalCode} {this.state.informations.city}</em>
                </div>

                <hr className="message-separator" />
              </>
            )}


            {!this.readOnly && (this.state.orderType === 1 || this.state.orderType === 4) && (
              <div className="restaurant-info-text bullet">

                {this.state.orderType === 1 && (
                  <span>Temps de préparation actuel: {this.props.settings.takeawaySettings.prepTime || 15} mins</span>
                )}

                {this.state.orderType === 4 && (
                  <span>Temps de livraison actuel: {this.props.settings.deliverySettings.deliveryTime || 15} mins</span>
                )}
              </div>
            )}

            {this.state.orderType === 1 && this.props.settings.takeawaySettings.customMessage ? (
              <div className="restaurant-info-text bullet">
                {this.props.settings.takeawaySettings.customMessage}
              </div>
            ) : this.state.orderType === 4 && this.props.settings.deliverySettings.customMessage ? (
              <div className="restaurant-info-text bullet">
                {this.props.settings.deliverySettings.customMessage}
              </div>
            ) : null}


            <Breakpoint small down>
              {this.props.settings.takeaway === true && this.props.settings.delivery === true && this.props.cart.orderType !== 2 && (this.props.settings.orderTypePopup === undefined || this.props.settings.orderTypePopup === false) && (
                <div className="cart-product-container">
                  <div className="cart-title">Mode de retrait</div>
                  <div className="order-type-pick">
                    {this.props.settings.orderSchedule && (this.props.settings.orderSchedule[now.getDay()].length > 0 || this.props.settings.orderSchedule[dayOfTheWeek].length > 0) ? (
                      <div
                        onClick={() => this.handleOrderTypeChange(1)}
                        className={`order-type-choice ${this.state.orderType === 1 ? 'selected' : ''}`}
                      >
                        {getOrderType(1)}
                        {this.state.orderType === 1 && (
                          <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                        )}
                      </div>
                    ) : (
                      <div
                        className={`order-type-choice disabled`}
                      >
                        {getOrderType(1)}
                        {this.state.orderType === 1 && (
                          <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                        )}
                      </div>
                    )}


                    {this.props.settings.deliverySchedule && (this.props.settings.deliverySchedule[now.getDay()].length > 0 || this.props.settings.deliverySchedule[dayOfTheWeek].length > 0) ? (
                      <div
                        onClick={() => this.handleOrderTypeChange(4)}
                        className={`order-type-choice ${this.state.orderType === 4 ? 'selected' : ''}`}
                      >
                        {getOrderType(4)}
                        {this.state.orderType === 4 && (
                          <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                        )}
                      </div>
                    ) : (
                      <div className={`order-type-choice disabled`}>
                        {getOrderType(4)}
                        {this.state.orderType === 4 && (
                          <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Breakpoint>
          </div>
        </div>

        <div className="category-container" id="sticky-header">

          <div className="options">
            {this.categories.map((category, idx) =>
              <CategoryRow
                category={category}
                key={category.id}
                idx={idx}
                selected={idx === this.state.selectedCategoryIdx}
                selectCategory={this.selectCategory}
              />
            )}
          </div>
        </div>


        {this.props.match.params.id === 'shinzzo' && (
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {this.categories.map((category, idx) =>
              <div>
                <CategoryLogo
                  category={category}
                  key={category.id}
                  idx={idx}
                  selected={idx === this.state.selectedCategoryIdx}
                  selectCategory={this.selectCategory}
                />
              </div>
            )}
          </Masonry>
        )}


        <Breakpoint medium up>
          <div className="product-grid">
            <div className="product-container">
              {this.state.sections.map((section, index) => (
                <div key={index}>
                  <Section title={section.title} id={section.id} description={section.description} />
                  <div className="product">
                    {section.data.map((product) =>
                      <ProductRow
                        cartProducts={this.props.cart.products}
                        product={product}
                        key={product.id}
                        toggleModal={this.toggleModal}
                        orderType={this.props.cart.orderType}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
            {/* {this.readOnly === false && ( */}
            <div className="cart">
              {this.props.settings.takeaway === true && this.props.settings.delivery === true && this.props.cart.orderType !== 2 && (this.props.settings.orderTypePopup === undefined || this.props.settings.orderTypePopup === false) && (
                <div className="cart-product-container">
                  <div className="cart-title">Mode de retrait</div>
                  <div className="order-type-pick">
                    {this.props.settings.orderSchedule && (this.props.settings.orderSchedule[now.getDay()].length > 0 || this.props.settings.orderSchedule[dayOfTheWeek].length > 0) ? (
                      <div
                        onClick={() => this.handleOrderTypeChange(1)}
                        className={`order-type-choice ${this.state.orderType === 1 ? 'selected' : ''}`}
                      >
                        {getOrderType(1)}
                        {this.state.orderType === 1 && (
                          <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                        )}
                      </div>
                    ) : (
                      <div className={`order-type-choice disabled`}>
                        {getOrderType(1)}
                        {this.state.orderType === 1 && (
                          <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                        )}
                      </div>
                    )}


                    {this.props.settings.deliverySchedule && (this.props.settings.deliverySchedule[now.getDay()].length > 0 || this.props.settings.deliverySchedule[dayOfTheWeek].length > 0) ? (
                      <div
                        onClick={() => this.handleOrderTypeChange(4)}
                        className={`order-type-choice ${this.state.orderType === 4 ? 'selected' : ''}`}
                      >
                        {getOrderType(4)}
                        {this.state.orderType === 4 && (
                          <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                        )}
                      </div>
                    ) : (
                      <div className={`order-type-choice disabled`}>
                        {getOrderType(4)}
                        {this.state.orderType === 1 && (
                          <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="cart-title">Ma commande</div>
              {this.state.canOrder === true && (
                <div className="cart-content">
                  {this.props.cart.products.map((product, index) =>
                    <ProductCartRow
                      product={product}
                      key={index}
                      index={index}
                      onClick={this.onClickCartProduct}
                      cart={this.props.cart}
                    />
                  )}
                </div>
              )}

              {!this.state.canOrder ? (
                <div><em>La commande sur internet est momentanement suspendu</em></div>
              ) : this.props.cart.products.length === 0 ? (
                <div><em>Votre commande est vide</em></div>
              ) : (
                <div className="cart-bottom">
                  {this.props.cart.discount > 0 && (
                    <div className="cart-product-item discount-row">
                      <div className="cart-product-row" style={{ cursor: 'unset' }}>
                        <div>Sous-total</div>
                        <div>{(this.props.cart.subtotal).toFixed(2)} €</div>
                      </div>
                    </div>
                  )}

                  {this.props.cart.discount > 0 && this.props.cart.subtotal !== this.props.cart.total && (
                    <div className="cart-product-item discount-row">
                      <div className="cart-product-row" style={{ cursor: 'unset' }}>
                        <div>Remise {this.state.orderType === 1 ? 'emporté' : this.state.orderType === 4 ? 'livraison' : ''} {this.props.cart.discount}%</div>
                        <div>-{(this.props.cart.subtotal - this.props.cart.total).toFixed(2)} €</div>
                      </div>
                    </div>
                  )}

                  {this.props.cart.coupon !== null ? this.props.cart.coupon?.discountType === 'percent' ? (
                    <div className="cart-product-item discount-row">
                      <div className={`cart-row`} style={{ width: '100%', justifyContent: 'space-between' }}>
                        <div>
                          <span className="remove-coupon-button" onClick={this.removeCoupon}>X</span>
                          Code Promo {this.props.cart.coupon.discountPercent}%
                        </div>
                        <div>-{computeDiscount(this.props.cart.total, this.props.cart.coupon.discountPercent, false).toFixed(2)}€</div>
                      </div>
                    </div>
                  ) : this.props.cart.coupon?.discountType === 'price' ? (
                    <div className="cart-product-item discount-row">
                      <div className={`cart-row`} style={{ width: '100%', justifyContent: 'space-between' }}>
                        <div>
                          <span className="remove-coupon-button" onClick={this.removeCoupon}>X</span>
                          Code Promo {this.props.cart.coupon.discountPrice}€
                        </div>
                        <div>-{(this.props.cart.total - this.props.cart.coupon.discountPrice).toFixed(2)}€</div>
                      </div>
                    </div>
                  ) : this.props.cart.coupon?.discountType === 'product' ? (
                    <div className="cart-product-item discount-row">
                      <div className={`cart-row`} style={{ width: '100%', justifyContent: 'space-between' }}>
                        <div>
                          <span className="remove-coupon-button" onClick={this.removeCoupon}>X</span>
                          Code Promo {this.props.cart.coupon.discountProduct}
                        </div>
                        <div></div>
                      </div>
                    </div>
                  ) : null : null}

                  <div className="footer-desktop" onClick={this.goToCart}>
                    <div className="confirm-btn">
                      <span>{this.props.cart.totalProducts}</span>
                      <span>Ma commande</span>
                      <span>{this.getOrderTotal().toFixed(2)} €</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* )} */}

          </div>
        </Breakpoint>

        <Breakpoint small down>
          <div className="product-grid">
            <div className="product-container">
              {this.state.sections.map((section, index) => (
                <div className="section-container" key={index}>
                  <Section title={section.title} id={section.id} description={section.description} />
                  {section.data.map((product) =>
                    <div key={product.id}>
                      <ProductRow
                        cartProducts={this.props.cart.products}
                        product={product}
                        key={product.id}
                        toggleModal={this.toggleModal}
                        orderType={this.props.cart.orderType}
                      />
                      <div className="product-margin" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {this.readOnly === false && !this.state.canOrder ? (
            <Link className="footer">
              <div className="confirm-btn"><em>La commande sur internet est désactivée</em></div>
            </Link>
          ) : this.readOnly === false && this.props.cart.products.length > 0 ? (
            <div className="footer" onClick={this.goToCart}>
              <div className="confirm-btn">
                <span>{this.props.cart.totalProducts}</span>
                <span>Ma commande</span>
                <span>{`${this.props.cart.total.toFixed(2)} €`}</span>
              </div>
            </div>
          ) : null}

        </Breakpoint>

        <FooterBar orderType={this.state.orderType} />

        <Modal
          show={this.state.showProductDetail}
          className="custom-map-modal"
          aria-labelledby="example-custom-modal-styling-title"
          onHide={this.closeProductModal}
          size="md"
          animation
          centered
        >
          <ProductDetail
            cart={this.props.cart}
            product={this.state.selectedProduct}
            closeModal={this.closeProductModal}
            openRecommendation={this.openRecommendation}
            updateIndex={this.state.selectedIdx}
            options={this.options}
            readOnly={this.readOnly}
            canOrder={this.state.canOrder}
          />
        </Modal>

        <Modal
          show={this.state.showRecommendation}
          onHide={() => this.closeProductRecommendation(true)}
          centered
          size="md"
          className="custom-map-modal"
          dialogClassName="w-full"
        >
          <ProductRecommendation
            products={this.allProducts}
            recommendation={this.state.selectedRecommendation}
            showProduct={this.toggleModal}
            close={this.closeProductRecommendation}
          />
        </Modal>

      </Container >
    )
  }
}

// redux

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    settings: state.settings,
    recommendation: state.recommendation,
    colors: state.colors
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateColors: (useDarkTheme) => dispatch(updateColors(useDarkTheme)),
    updateRestoInfo: (a, b, c, d, e, f) => dispatch(updateRestoInfo(a, b, c, d, e, f)),
    updateRestoSettings: (a, b, c, d, e, f, g, h) => dispatch(updateRestoSettings(a, b, c, d, e, f, g, h)),
    updateType: (type) => dispatch(updateType(type)),
    applyDiscount: (d) => dispatch(applyDiscount(d)),
    updateOrderSchedule: (schedule) => dispatch(updateOrderSchedule(schedule)),
    updateDeliverySchedule: (schedule) => dispatch(updateDeliverySchedule(schedule)),
    updateDeliverySettings: (s) => dispatch(updateDeliverySettings(s)),
    updateTakeawaySettings: (s) => dispatch(updateTakeawaySettings(s)),
    resetCart: () => dispatch(resetCart()),
    applyCoupon: (coupon) => dispatch(applyCoupon(coupon)),
    pickedOrderType: () => dispatch(pickedOrderType()),
    setDiscountSettings: (settings) => dispatch(setDiscountSettings(settings)),
    setHasRefusedRecommendation: () => dispatch(setHasRefused()),
    incrementRecommendationCount: () => dispatch(incrementRecommendationCount()),
    setRecommendation1: (s) => dispatch(setRecommendation1(s)),
    setRecommendation2: (s) => dispatch(setRecommendation2(s)),
    flushRecommendation: (s) => dispatch(flushRecommendations(s)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('')(Restaurant));
